import React, { useState, useEffect } from 'react';

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      console.log('Network status changed:', navigator.onLine ? 'Online' : 'Offline');
      setIsOnline(navigator.onLine);
    };

    // Attach event listeners for online and offline events
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Initial check
    updateOnlineStatus();

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  if (isOnline) {
    return null; // Do not display anything if the user is online
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 text-white text-center p-4">
      <div>
        <h1 className="text-2xl font-bold mb-4">You are Offline</h1>
        <p className="text-lg">Please check your internet connection.</p>
      </div>
    </div>
  );
};

export default NetworkStatus;
