import React from 'react';
import './Footer.css';
import logo from '../../assets/icon.png';
import PolicyModal from '../PolicyModal/PolicyModal';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <img src={logo} alt="Logo" className="footer-logo" />
        <p className="footer-description">{process.env.REACT_APP_NAME} este primul portal de achiziții publice dedicat proiectelor de construcții, oferind transparență și acces facil la procedurile de achiziții, de la planificare până la publicarea în SEAP și integrarea datelor în platformă.</p>
      </div>
      <div className="footer-column">
      <h3 className="footer-title">Link-uri</h3>
        <ul className="footer-links">
          <li><a href="/licitatii">Licitatii</a></li>
          <li><a href="#link2">Proiecte</a></li>
          <li><a href="/abonamente">Abonamente</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h3 className="footer-title">Legal</h3>
        <ul className="footer-legal">
          <li><PolicyModal /></li>
          <li><a href="#terms">Termeni de utilizare</a></li>
        </ul>
      </div>
      <div className="footer-column">
  <h3 className="footer-title">Contact</h3>
      <div className="footer-map">
        <iframe
          title = "Harta"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.1321347973726!2d26.084234876662016!3d44.45096170046983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201ffd7e0a5b3%3A0xbe93261d0e8a951!2sBl.%20D1%2C%20Calea%20Victoriei%20155%2C%20Bucure%C8%99ti%20010073!5e0!3m2!1sen!2sro!4v1728641109678!5m2!1sen!2sro"
          width="100%"
          height="150"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      <p className="footer-address">
        <i className="fas fa-map-marker-alt"></i> Calea Victoriei, nr. 155, bl. D1, Sector 1, București
      </p>
      <p className="footer-phone">
        <i className="fas fa-phone-alt"></i> <a href="tel:+40212223333">+40 212 223 333</a>
      </p>
      <p className="footer-email">
        <i className="fas fa-envelope"></i> <a href="mailto:info@go4projects.ro">info@go4projects.ro</a>
      </p>
    </div>

    </footer>
  );
};

export default Footer;
