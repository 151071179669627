import React, { useState, useEffect } from "react";

const InstallPWAButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const checkIfStandalone = () => {
      setIsStandalone(window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone);
    };

    checkIfStandalone();

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", () => {
      console.log("PWA installed");
      setIsInstallable(false);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      window.removeEventListener("appinstalled", () => setIsInstallable(false));
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the PWA installation");
      } else {
        console.log("User dismissed the PWA installation");
      }
      setDeferredPrompt(null);
    }
  };

  if (isStandalone || !isInstallable) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-lg shadow-lg z-50 flex items-center">
      <p className="mr-4 text-sm">Instalează aplicația pentru o experiență mai bună!</p>
      <button
        onClick={handleInstallClick}
        className="bg-white text-blue-600 px-3 py-1 rounded-lg font-medium hover:bg-gray-200 transition"
      >
        Instalează
      </button>
    </div>
  );
};

export default InstallPWAButton;
