import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchNoticeDetails } from '../../services/publicTendersApi';
import pdfIcon from '../../assets/icons/pdf-icon.png';
import docIcon from '../../assets/icons/doc-icon.png';
import xlsIcon from '../../assets/icons/xls-icon.png';
import zipIcon from '../../assets/icons/zip-icon.png';
import defaultIcon from '../../assets/icons/file-icon.png';
import './AuctionDetails.css';
import Utility from './../../utils/Utility';
import Seo from '../../components/Seo/Seo';

const AuctionDetails = () => {
  const { noticeNo } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDetails = async () => {
      try {
        setLoading(true);
        const data = await fetchNoticeDetails(noticeNo);
        setDetails(data);
      } catch (err) {
        setError(err.message || 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    getDetails();
  }, [noticeNo]);

  const getFileIcon = (extension) => {
    switch (extension) {
      case 'pdf':
        return pdfIcon;
      case 'doc':
      case 'docx':
        return docIcon;
      case 'xls':
      case 'xlsx':
        return xlsIcon;
      case 'zip':
      case 'rar':
      case '7z':
      case 'tar':
        return zipIcon;
      default:
        return defaultIcon;
    }
  };

  const handleFileDownload = async (doc) => {
    const { noticeDocumentUrl, noticeDocumentName } = doc;
    const cNoticeId = details.specificView?.cNoticeId;
    const noticeNo = details.specificView?.noticeNo;
  
    if (!noticeDocumentUrl || !noticeDocumentName || !cNoticeId || !noticeNo) {
      console.error("Missing data for file download.");
      alert("Some required information is missing. Please try again.");
      return;
    }
  
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/SU_ContractList/download/?noticeDocumentUrl=${encodeURIComponent(
        noticeDocumentUrl
      )}&noticeDocumentName=${encodeURIComponent(noticeDocumentName)}&cNoticeId=${cNoticeId}&noticeNo=${encodeURIComponent(
        noticeNo
      )}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept": "application/octet-stream",
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to download the file. Please try again.");
      }
  
      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
  
      // Extrage numele fișierului din antet dacă există
      let fileName = noticeDocumentName.replace(/\.p7m|\.p7s$/, "");
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="(.+)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }
  
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // Setează numele fișierului
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error during file download:", error);
      alert("An error occurred while attempting to download the file.");
    }
  };
  

  // const handleFileDownload = async (doc) => {
  //   const { noticeDocumentUrl, noticeDocumentName } = doc;
  //   const cNoticeId = details.cNoticeView?.cNoticeId;

  //   if (!noticeDocumentUrl || !noticeDocumentName || !cNoticeId) {
  //     console.error('Missing data for file download.');
  //     return;
  //   }

  //   const url = `${process.env.REACT_APP_BASE_URL}/api/SU_ContractList/download/?noticeDocumentUrl=${encodeURIComponent(
  //     noticeDocumentUrl
  //   )}&noticeDocumentName=${encodeURIComponent(noticeDocumentName)}&cNoticeId=${cNoticeId}&noticeNo=${noticeNo}`;

  //   //window.open(url, '_blank');

  //   const response = await fetch(url, { method: "GET", headers: {
  //     "Accept": "application/octet-stream",
  //   } });
  //   const fileUrl = response.url;
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = noticeDocumentName.replace(/\.p7m|\.p7s$/, '');
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();


  // };


  if (loading) {
    return <p className="loading-text">Se încarcă...</p>;
  }

  if (error) {
    return <p className="error-text">Eroare: {error}</p>;
  }

  if (!details) {
    return <p>Nu există date disponibile.</p>;
  }

  const pageTitle = `${details.stickyData?.contractTitle || 'Detalii Licitație'} - ${process.env.REACT_APP_NAME}`;
  const pageDescription = `Informații despre licitația ${details.stickyData?.contractTitle || 'N/A'}, publicată de ${details.stickyData?.caName || 'N/A'}.`;
  const pageUrl = `${window.location.origin}/licitatie/${noticeNo}`;
  const logoUrl = `${process.env.REACT_APP_BASE_URL}/assets/logo.png`;

  return (
    <>
    <Seo
    pageTitle={pageTitle}
    pageDescription={pageDescription}
    pageUrl={pageUrl}
    logoUrl={logoUrl}
    keywords={"achiziții publice, licitații, construcții, Go4Projects"}
    />
    <div className="container">
      <h1 className="page-title">Detalii Licitație</h1>

      {/* Informații Cheie */}
      <div className="row">
        <div className="col-2-3">
          <h2 className="section-title">Informații Cheie</h2>
          <div className="section-content">
            <p><strong>Titlu Contract:</strong> {details.stickyData?.contractTitle || 'N/A'}</p>
            <p><strong>Număr Licitație:</strong> {details.stickyData?.noticeNumber || 'N/A'}</p>
            <p><strong>Autoritate Contractantă:</strong> {details.stickyData?.caName || 'N/A'}
            <br /> {details.section1View.noticeEntityAddress.Address || "N/A" + " " + details.Section1View.NoticeEntityAddress.PostalCode || "N/A"}</p>
            <p><strong>Tip Procedura:</strong> {details.stickyData.sysProcedureType || 'N/A'}</p>
          </div>
        </div>
        <div className="col-1-3">
          <h2 className="section-title">Termene</h2>
          <div className="section-content">
            <p><strong>Termen Limită:</strong> {details.section4View?.tenderAvailabilityDeadline || 'N/A'}</p>
            <p><strong>Dată Publicare:</strong> {Utility.FormatDate(details.stickyData?.publicationDate) || 'N/A'}</p>
          </div>
        </div>
      </div>

      {/* Valori Financiare */}
      <div className="row">
        <div className="col-1-3">
          <h2 className="section-title">Valori Financiare</h2>
          <div className="section-content">
            <p><strong>Valoare Estimată:</strong> {Utility.ConvertEstimatedValue(details.section21View.estimatedValue)} {details.section21View.currencyCode}</p>
            <p><strong>Garanție Participare:</strong> {details.section6View?.depositsAndWarranties || 'N/A'}</p>
          </div>
        </div>
        <div className="col-1-3">
          <h2 className="section-title">Coduri CPV</h2>
          <div className="section-content">
            <p><strong>Cod CPV:</strong> {details.section21View?.mainCpvCode || 'N/A'}</p>
          </div>
        </div>
        <div className="col-1-3">
          <h2 className="section-title">Fișiere Disponibile</h2>
          <div className="section-content">
            {details.dfNoticeSectionFiles?.dfNoticeDocs?.length > 0 ? (
              details.dfNoticeSectionFiles.dfNoticeDocs.map((doc, index) => {
                const fileName = doc.noticeDocumentName.replace(/\.p7m|\.p7s$/, '') || 'Document';
                const fileExtension = fileName.split('.').pop().toLowerCase();

                return (
                  <div key={index} className="file-item">
                    <img
                      src={getFileIcon(fileExtension)}
                      alt={`${fileExtension} icon`}
                      className="file-icon"
                    />
                    <button
                      onClick={() => handleFileDownload(doc)}
                      className="download-link"
                    >
                      {fileName}
                    </button>
                  </div>
                );
              })
            ) : (
              <p>Nu există fișiere disponibile.</p>
            )}
          </div>
        </div>
      </div>

      {/* Situație economică și financiară si Capacitate tehnică și profesională */}
      <div className="row">
        <div className="col-50">
          <h2 className="section-title">Situație economică și financiară</h2>
          <div className="section-content">
            <p>{details.section3View?.depositsAndWarranties || 'N/A'}</p>
          </div>
        </div>
        <div className="col-50">
          <h2 className="section-title">Capacitate tehnică și profesională</h2>
          <div className="section-content">
            <p>{details.section3View?.tpCriteriaQAStandardMin || 'N/A'}</p>
          </div>
        </div>
      </div>

      {/* Informații Adiționale */}
      {/* <div className="row">
        <div className="col-100">
          <h2 className="section-title">Informații Adiționale</h2>
          <div className="section-content">
            <p>{details.section6View?.additionalInformation || 'N/A'}</p>
          </div>
        </div>
      </div> */}

    </div>
    </>
  );
};

export default AuctionDetails;
