import React, { useState, useEffect } from "react";
import {
  getUserInfo,
  updateUserCompanyInfo,
  updateUserContactInfo,
  changeUserPassword,
} from "../../services/accountService";
import { Pie, Line } from "react-chartjs-2";
//import Utility from "./../../utils/Utility";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const UserAccount = () => {
  const [userData, setUserData] = useState(null);
  const [companyEdit, setCompanyEdit] = useState(false);
  const [contactEdit, setContactEdit] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const { success, error, user } = await getUserInfo();
      if (success) {
        setUserData(user);
      } else {
        setError(error || "A apărut o eroare la încărcarea datelor utilizatorului.");
      }
    };

    fetchUserData();
  }, []);

  const handleCompanySave = async () => {
    const success = await updateUserCompanyInfo(userData);
    if (success) setCompanyEdit(false);
  };

  const handleContactSave = async () => {
    const success = await updateUserContactInfo(userData);
    if (success) setContactEdit(false);
  };

  const handlePasswordChange = async () => {
    const success = await changeUserPassword({ password: newPassword });
    if (success) {
      setPasswordEdit(false);
      setNewPassword("");
    }
  };

  const pieData = {
    labels: ["Abonament Premier", "Abonament Standard", "Abonament Free"],
    datasets: [
      {
        label: "Tipuri de Abonamente",
        data: [45, 35, 20],
        backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
      },
    ],
  };

  const lineData = {
    labels: ["Ian", "Feb", "Mar", "Apr", "Mai", "Iun"],
    datasets: [
      {
        label: "Venituri lunare (RON)",
        data: [3000, 4000, 3500, 5000, 6000, 5500],
        borderColor: "#3b82f6",
        backgroundColor: "rgba(59, 130, 246, 0.2)",
        tension: 0.4,
      },
    ],
  };

  const paymentHistory = [
    { date: "2024-01-15", description: "Abonament Premier Diamond", amount: "500 RON" },
    { date: "2023-12-10", description: "Abonament Standard", amount: "250 RON" },
  ];

  const alerts = [
    { date: "2024-01-15", message: "Licitație nouă pe codul CPV urmărit: 123456." },
    { date: "2024-01-10", message: "Termen de depunere pentru licitația XYZ: 15 ianuarie." },
  ];

  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4 py-6 bg-gray-100">
      <div className="container mx-auto grid grid-cols-1 gap-6">
        {/* Row 1: Panels */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Panels */}
          {[
            { icon: "fas fa-list", value: "120", label: "Licitații pe codurile mele", color: "text-blue-500" },
            { icon: "fas fa-database", value: "15,000", label: "Licitații totale în sistem", color: "text-green-500" },
            { icon: "fas fa-star", value: "35", label: "Licitații urmărite", color: "text-yellow-500" },
          ].map((item, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4 flex items-center">
              <div className={`${item.color} text-5xl mr-4`}>
                <i className={item.icon}></i>
              </div>
              <div>
                <p className="text-3xl font-bold">{item.value}</p>
                <p className="text-gray-600">{item.label}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Row 2: Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="col-span-2 bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">Performanțe Financiare</h2>
            <Line data={lineData} />
          </div>
          <div className="col-span-1 bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">Distribuția Abonamentelor</h2>
            <Pie data={pieData} />
          </div>
        </div>

        {/* User Info Sections */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* User Sections */}
          {[
            {
              title: "Date Companie",
              edit: companyEdit,
              setEdit: setCompanyEdit,
              onSave: handleCompanySave,
              fields: [
                { label: "Nume Companie", value: userData?.company, key: "company" },
                { label: "Email", value: userData?.email, key: "email" },
              ],
            },
            {
              title: "Date Utilizator",
              edit: contactEdit,
              setEdit: setContactEdit,
              onSave: handleContactSave,
              fields: [
                { label: "Nume", value: userData?.name, key: "name" },
                { label: "Telefon", value: userData?.phone, key: "phone" },
              ],
            },
            {
              title: "Schimbare Parolă",
              edit: passwordEdit,
              setEdit: setPasswordEdit,
              onSave: handlePasswordChange,
              fields: [{ label: "Parolă Nouă", value: newPassword, key: "password" }],
            },
          ].map((section, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">{section.title}</h2>
              {section.edit ? (
                <div>
                  {section.fields.map((field) => (
                    <div key={field.key} className="mb-4">
                      <label className="block mb-2">{field.label}</label>
                      <input
                        type="text"
                        value={field.value || ""}
                        onChange={(e) =>
                          setUserData((prev) => ({ ...prev, [field.key]: e.target.value }))
                        }
                        className="border p-2 w-full"
                      />
                    </div>
                  ))}
                  <button onClick={section.onSave} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Salvează
                  </button>
                  <button
                    onClick={() => section.setEdit(false)}
                    className="bg-red-500 text-white px-4 py-2 ml-4 rounded hover:bg-red-600"
                  >
                    Anulează
                  </button>
                </div>
              ) : (
                <div>
                  {section.fields.map((field) => (
                    <p key={field.key}>
                      <strong>{field.label}:</strong> {field.value || "N/A"}
                    </p>
                  ))}
                  <button
                    onClick={() => section.setEdit(true)}
                    className="bg-gray-500 text-white px-4 py-2 mt-4 rounded hover:bg-gray-600"
                  >
                    Modifică
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Row 4: Payments and Alerts */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="col-span-1 bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">Istoric Plăți</h2>
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border p-2">Data</th>
                  <th className="border p-2">Descriere</th>
                  <th className="border p-2">Sumă</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment, idx) => (
                  <tr key={idx}>
                    <td className="border p-2">{payment.date}</td>
                    <td className="border p-2">{payment.description}</td>
                    <td className="border p-2">{payment.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-span-2 bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">Alerte</h2>
            <ul className="list-disc pl-5">
              {alerts.map((alert, idx) => (
                <li key={idx} className="mb-2">
                  <strong>{alert.date}</strong>: {alert.message}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
