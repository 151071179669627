import React from 'react';
import ReactDOM from 'react-dom/client'; // Actualizat pentru a folosi createRoot
import './index.css';
import './tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Înregistrăm Service Worker-ul
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('ServiceWorker înregistrat cu succes:', registration);
      })
      .catch((error) => {
        console.error('Eroare la înregistrarea ServiceWorker:', error);
      });
  });
}

// Obține elementul root din DOM
const rootElement = document.getElementById('root');

// Creează un root pentru React folosind createRoot
const root = ReactDOM.createRoot(rootElement);

// Rendează aplicația în root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Măsurăm performanțele aplicației
reportWebVitals();
